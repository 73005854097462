import t from '@motional-cc/fe/tools/translate';
import { Security, useOktaAuth } from '@okta/okta-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import noop from 'lodash/noop';
import { Suspense } from 'react';
import {
  Navigate,
  Outlet,
  RouteObject,
  createBrowserRouter,
} from 'react-router-dom';
import { queryClient } from 'src/api/hooks/service';
import { oktaAuth } from 'src/auth/okta';
import FullWidthLoader from 'src/components/common/FullWidthLoader';
import PageTitle from 'src/components/common/PageTitle';
import { AppSection } from 'src/components/Dashboard/DashboardApp';
import Messages from 'src/components/Messages/Messages';
import { MessagesProvider } from 'src/components/Messages/messages-context';
import { CREATE_MODAL_STATE_PARAM } from 'src/components/VehicleRegistration/VehicleListPage';
import { ThemeProvider } from 'src/contexts/theme-context';
import { UrlSettingsProvider } from 'src/contexts/url-settings-context';
import { OrString } from 'src/interface/utility';
import PageNotFound from 'src/screens/PageNotFound';
import ThemedMeta from 'src/screens/ThemedIcons';
import { lazyWithRetry } from 'src/tools/lazy-with-retry';
import { isNullish } from 'src/tools/types';
import Analytics from './Analytics';
import Authenticated from './Authenticated';
import ErrorReportingContext from './ErrorReportingContext';

const Unauthorised = lazyWithRetry(() => import('src/components/Splash'), {
  throwAfterAttempts: true,
});

const Dashboard = lazyWithRetry(
  () => import('src/components/Dashboard/Dashboard'),
  { throwAfterAttempts: true },
);
const Logout = lazyWithRetry(() => import('src/components/Logout'), {
  throwAfterAttempts: true,
});
const FleetMonitoring = lazyWithRetry(
  () => import('src/components/FleetMonitoring'),
  {
    throwAfterAttempts: true,
  },
);
const VehicleMonitoring = lazyWithRetry(
  () => import('src/components/VehicleMonitoring'),
  {
    throwAfterAttempts: true,
  },
);
const VehicleRegistration = lazyWithRetry(
  () => import('src/components/VehicleRegistration'),
  { throwAfterAttempts: true },
);
const Bundles = lazyWithRetry(
  () => import('src/components/Bundles/BundlesLayout'),
  { throwAfterAttempts: true },
);
const Users = lazyWithRetry(() => import('src/components/Users/UsersLayout'), {
  throwAfterAttempts: true,
});
const UserTogglesPage = lazyWithRetry(
  () => import('src/components/UserTogglesPage'),
  { throwAfterAttempts: true },
);
const VoControlsPage = lazyWithRetry(
  () => import('src/components/VoControlsPage'),
  { throwAfterAttempts: true },
);
const MyVehicle = lazyWithRetry(() => import('src/components/MyVehicle'), {
  throwAfterAttempts: true,
});
const FleetManagementPage = lazyWithRetry(
  () => import('src/components/FleetManagement'),
  { throwAfterAttempts: true },
);
const VehicleSchedulingPage = lazyWithRetry(
  () => import('src/components/VehicleScheduling'),
  { throwAfterAttempts: true },
);
const VehicleConfigurationPage = lazyWithRetry(
  () => import('src/components/VehicleConfiguration'),
  { throwAfterAttempts: true },
);
const DataColosseum = lazyWithRetry(
  () => import('src/components/DataColosseum'),
  { throwAfterAttempts: true },
);
const Geppetto = lazyWithRetry(() => import('src/components/Geppetto'), {
  throwAfterAttempts: true,
});
const Billboard = lazyWithRetry(() => import('src/components/Billboard'), {
  throwAfterAttempts: true,
});
const CallbackRedirectPage = lazyWithRetry(
  () => import('src/screens/CallbackRedirectPage'),
  {
    throwAfterAttempts: true,
  },
);
const renderRedirects = ({ from, to }: { from: string[]; to: string }) =>
  from.map((redirectedPath) => ({
    path: redirectedPath,
    element: <Navigate replace to={to} />,
  }));

const titleise = (appSection: OrString<AppSection | 'Dashboard'>) =>
  `${t(`appSection.${appSection}`)} | Command Center`;

function ContextStack() {
  return (
    <ErrorReportingContext>
      {/* we're handling restoring original URI ourselves */}
      <Security oktaAuth={oktaAuth} restoreOriginalUri={noop}>
        <ThemeProvider>
          <ThemedMeta />
          <UrlSettingsProvider>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />

              <Analytics>
                <MessagesProvider>
                  <Messages />
                  <Suspense
                    fallback={
                      <div className="the-app__loader">
                        <FullWidthLoader />
                      </div>
                    }
                  >
                    <Outlet />
                  </Suspense>
                </MessagesProvider>
              </Analytics>
            </QueryClientProvider>
          </UrlSettingsProvider>
        </ThemeProvider>
      </Security>
    </ErrorReportingContext>
  );
}

function AuthenticatedOnly() {
  const oktaAuth = useOktaAuth();

  const isAuthenticated = oktaAuth?.authState?.isAuthenticated;

  return (
    isNullish(isAuthenticated) ?
      <div className="the-app__loader">
        <FullWidthLoader />
      </div>
    : isAuthenticated ?
      <Authenticated>
        <Outlet />
      </Authenticated>
    : <PageNotFound />
  );
}

function UnauthenticatedOnly() {
  const oktaAuth = useOktaAuth();

  const isUnauthenticated =
    isNullish(oktaAuth?.authState?.isAuthenticated) ? null : (
      !oktaAuth?.authState?.isAuthenticated
    );

  return (
    isNullish(isUnauthenticated) ?
      <div className="the-app__loader">
        <FullWidthLoader />
      </div>
    : isUnauthenticated ? <Outlet />
    : <Navigate replace to="/" />
  );
}

const onlyUnprotectedRoute = (routeProps: RouteObject): RouteObject => ({
  path: routeProps.path,
  element: <UnauthenticatedOnly />,
  children: [routeProps],
});

export const router = createBrowserRouter([
  {
    path: '/',
    element: <ContextStack />,
    children: [
      {
        path: '/callback',
        element: <CallbackRedirectPage />,
      },

      onlyUnprotectedRoute({
        path: '/login',
        element: (
          <PageTitle title={titleise('Log in')}>
            <Unauthorised />
          </PageTitle>
        ),
      }),
      onlyUnprotectedRoute({
        path: '/auth-error',
        element: (
          <PageTitle title={titleise('Log in')}>
            <Unauthorised hasAuthError />
          </PageTitle>
        ),
      }),

      {
        path: '/',
        element: <AuthenticatedOnly />,
        children: [
          {
            path: '/logout',
            element: <Logout />,
          },

          {
            path: '/fleet-monitoring',
            element: (
              <PageTitle title={titleise('FleetMonitoring')}>
                <FleetMonitoring />
              </PageTitle>
            ),
          },

          {
            path: '/vehicle-monitoring',
            element: (
              <PageTitle title={titleise('VehicleMonitoring')}>
                <VehicleMonitoring />
              </PageTitle>
            ),
          },

          {
            path: '/body-controls',
            element: (
              <PageTitle title={titleise('Geppetto')}>
                <Geppetto />
              </PageTitle>
            ),
          },

          ...renderRedirects({
            from: [
              '/bundles/compare',
              '/bundles/list-gen-2',
              '/bundles/list-legacy',
            ],
            to: '/bundles/bundle-list',
          }),

          {
            path: '/bundles',
            element: <Navigate replace to="/bundles/one-touch" />,
          },
          {
            path: '/bundles/:subpage',
            element: (
              <PageTitle title={titleise('Bundles')}>
                <Bundles />
              </PageTitle>
            ),
          },

          ...renderRedirects({
            from: ['/permission'],
            to: '/role-management/role-list',
          }),
          {
            path: '/role-management',
            element: <Navigate replace to="/role-management/role-list" />,
          },
          {
            path: '/role-management/:subpage',
            element: (
              <PageTitle title={titleise('UserSettings')}>
                <Users />
              </PageTitle>
            ),
          },
          {
            path: '/role-management/:subpage/:id',
            element: (
              <PageTitle title={titleise('UserSettings')}>
                <Users />
              </PageTitle>
            ),
          },

          {
            path: '/analytics',
            element: <Navigate replace to="/analytics/overview" />,
          },
          {
            path: '/analytics/:subpage',
            element: (
              <PageTitle title={titleise('Analytics')}>
                <DataColosseum />
              </PageTitle>
            ),
          },

          {
            path: '/vo-controls',
            element: <Navigate replace to="/vo-controls/controls" />,
          },
          {
            path: '/vo-controls/:subpage',
            element: (
              <PageTitle title={titleise('VoControls')}>
                <VoControlsPage />
              </PageTitle>
            ),
          },

          {
            path: '/field-support',
            element: (
              <PageTitle title={titleise('FieldSupport')}>
                <MyVehicle />
              </PageTitle>
            ),
          },

          {
            path: '/fleet-management',
            element: <Navigate replace to="/fleet-management/overview" />,
          },
          {
            path: '/fleet-management/:subpage',
            element: (
              <PageTitle title={titleise('FleetManagement')}>
                <FleetManagementPage />
              </PageTitle>
            ),
          },

          {
            path: '/vehicle-scheduling',
            element: <Navigate replace to="/vehicle-scheduling/missions" />,
          },
          {
            path: '/vehicle-scheduling/:subpage',
            element: (
              <PageTitle title={titleise('VehicleScheduling')}>
                <VehicleSchedulingPage />
              </PageTitle>
            ),
          },

          {
            path: '/vehicle-configuration',
            element: <Navigate replace to="/vehicle-configuration/vehicles" />,
          },
          {
            path: '/vehicle-configuration/:subpage',
            element: (
              <PageTitle title={titleise('VehicleConfiguration')}>
                <VehicleConfigurationPage />
              </PageTitle>
            ),
          },

          ...renderRedirects({
            from: ['/vehicle-registration/create'],
            to: `/vehicle-registration/list?${CREATE_MODAL_STATE_PARAM}=open`,
          }),
          {
            path: '/vehicle-registration',
            element: <Navigate replace to="/vehicle-registration/list" />,
          },
          {
            path: '/vehicle-registration/:subpage',
            element: (
              <PageTitle title={titleise('VehicleRegistration')}>
                <VehicleRegistration />
              </PageTitle>
            ),
          },
          {
            path: '/vehicle-registration/:subpage/:id',
            element: (
              <PageTitle title={titleise('VehicleRegistration')}>
                <VehicleRegistration />
              </PageTitle>
            ),
          },

          {
            path: '/billboard',
            element: <Navigate replace to="/billboard/blog" />,
          },
          {
            path: '/billboard/:subpage',
            element: (
              <PageTitle title={titleise('Billboard')}>
                <Billboard />
              </PageTitle>
            ),
          },

          {
            path: '/role-management/custom-user-settings/manage-toggles',
            element: <UserTogglesPage />,
          },

          ...renderRedirects({
            from: ['/callback', '/index', '/index.html', '/login'],
            to: '/',
          }),
          {
            path: '/',
            element: (
              <PageTitle title={titleise('Dashboard')}>
                <Dashboard />
              </PageTitle>
            ),
          },
        ],
      },

      { path: '*', element: <PageNotFound /> },
    ],
  },
]);
